@font-face {
  font-family: "Horizon";
  src: url("/fonts/Horizon.otf") format("opentype");
}

@font-face {
  font-family: "Albra";
  src: url("/fonts/AlbraMedium.otf") format("opentype");
  font-weight: medium;
}

@font-face {
  font-family: "Helvetica";
  src: url("/fonts/Helvetica-Light.ttf") format("truetype");
  font-weight: 300;
}

:root {
  --font-size-faq-question: 1.3rem;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-body);
  font-size: var(--font-size-body);
  color: var(--cc-colour-deepsea);
  background-color: var(--cc-colour-sand);
}

h1 {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-heading);
}

h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-subheading);
  font-size: var(--font-size-subheading);
  font-weight: var(--font-weight-subheading);
}

ul li,
ol li {
  margin-bottom: 16px;
  line-height: 1.5;
}

button {
  background-color: var(--cc-colour-deepsea);
}
