:root {
  --cc-colour-deepsea: #133d65;
  --cc-colour-sand: #f5efe4;
  --cc-colour-dune: #ddc1ab;
  --cc-colour-seaspray: #b9d7d7;
  --cc-colour-seaspray-light: #e6f4f4;
  --cc-colour-seaspray-hover: #ebf6f6;
  --cc-colour-error: #da3633;
  --cc-colour-warning: #f49f0a;
  --cc-colour-success: #16c60c;
  --cc-colour-link: blue;
  --cc-colour-link-visited: #c04cfd;

  --font-family-body: "Helvetica", "Arial", sans-serif;
  --font-size-body: 1.05rem;

  --font-family-heading: "Horizon", sans-serif;
  --font-size-heading: 3rem;

  --font-family-subheading: "Albra", sans-serif;
  --font-size-subheading: 1.5rem;
  --font-weight-subheading: 700;

  --font-family-button: "Helvetica", "Arial", sans-serif;
  --font-size-button: 1.2rem;
  --font-weight-button: 700;
}

html,
body {
  margin: 0;
  padding: 0;
  color: var(--cc-colour-deepsea);
  min-width: 300px;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--cc-colour-link);
  text-decoration: none;
}

a:hover {
  text-decoration: dotted underline;
}

a:visited {
  color: var(--cc-colour-link-visited);
}

/* Mobile */
@media only screen and (max-width: 378px) {
  :root {
    --font-size-body: 0.9rem;
  }
}
